<template>
  <default-layout>
    <v-container id="refund-management-manager" fluid tag="section">
      <page-loading :show="isLoading || loading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <detail :detail="detail" :permissions="permissions" :allowedActions="allowedActions"></detail>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const Detail = () => import('@/components/refund-management/manager/refund/detail');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    Detail,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.refundManager.permissions,
      allowedActions: (state) => state.refundManager.allowedActions,
      detail: (state) => state.refundManager.detail,
      loading: (state) => state.refundManager.loading,
    }),
  },
  async mounted() {
    await this.loadDetail();
  },
  methods: {
    async refreshAction() {
      await this.loadDetail();
    },
    async loadDetail() {
      try {
        this.isLoading = true;
        this.$store.commit('refundManager/RESET_DEFAULT');
        const response = await this.$store.dispatch(
          'refundManager/getDetailInitData',
          this.$route.params.uuid,
        );
        console.log('DETAIL REFUND MANAGER: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
